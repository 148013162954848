<template>
  <no-results
    :cta="{
      label: 'Take new task',
      class: `is-outlined ${loading ? 'is-loading' : ''}`
    }"
    icon="hand-pointer"
    title="No task selected"
    message="Select a task or take a new one."
    @click="takeTask"
  />
</template>

<script>
export default {
  name: "NoTaskSelected",
  data() {
    return {
      loading: false
    };
  },
  methods: {
    takeTask() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch("tasks/takeTask")
        .then(result => {
          if (result.data.taskId) {
            this.$router.push({
              path: `/admin/tasks/my/${result.data.taskId}`
            });
          }
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
